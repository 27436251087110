import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'

import { ConditionalRender } from '@/common/components'
import {
  ISafeNoteDraft,
  ISafeNoteHighlights,
  ISafeNoteRecipient,
  ISafeNoteSignatures,
  SafeNoteDocument,
  SafeNoteNavigation,
  Terms,
  ViewSafeAs
} from '@/features/safe-note'
import { SafeNoteContextProvider } from '@/features/safe-note/components/SafeNote/context'
import { useFullScreenContext } from '@/features/safe-note/context/FullScreenContext'
import { IUser } from '@/features/user'
import { SharedIcons } from '@/packages/icons'
import { Button, Col, Row } from '@/packages/ui'

import styles from './SafeNote.module.scss'

export interface SafeNoteProps {
  safeNoteId?: string
  sender: IUser | null
  recipient?: IUser // only need when save is already sent

  companyName: string
  stateOfIncorporation: string
  recipients: ISafeNoteRecipient[]

  selectedTermsId: Terms | undefined
  discountRate: number | undefined
  discountRateActive: boolean
  valuationCap: number | undefined
  valuationCapActive: boolean
  mfn: boolean

  signatures: ISafeNoteSignatures

  isSafeAccepted?: boolean
  isSafeDeclined?: boolean
  isSignatureAdded?: boolean
  viewAs: ViewSafeAs
  viewMode?: boolean
  onUpdateData: (value: Partial<ISafeNoteDraft>) => void
  highlights: ISafeNoteHighlights
  updateHighlights: (values: Partial<ISafeNoteHighlights>) => void
  onFinish?: () => void
  onDecline?: () => void
  onAccept?: () => void
  onNextStep?: () => void
}

const SafeNote: FC<SafeNoteProps> = (props: SafeNoteProps) => {
  const {
    recipients,
    viewMode,
    viewAs,
    isSafeAccepted,
    isSafeDeclined,
    onAccept,
    onDecline,
    onNextStep,
    signatures
  } = props

  const { isFullScreenActive, toggleFullScreen } = useFullScreenContext()

  const [recipientIndex, setRecipientIndex] = useState(0)

  useEffect(() => {
    const maxId = recipients.length - 1
    if (recipientIndex > maxId) {
      setRecipientIndex(0)
    }
  }, [recipients.length, recipientIndex])

  return (
    <>
      <SafeNoteContextProvider
        {...props}
        recipientIndex={recipientIndex}
        setRecipientIndex={setRecipientIndex}
      >
        <div
          className={clsx(
            styles.safeNoteContainer,
            viewMode && styles.viewMode,
            isFullScreenActive && styles.fullScreen
          )}
        >
          <SafeNoteNavigation />
          <Col>
            <SafeNoteDocument
              fullScreen={isFullScreenActive}
              toggleFullScreen={toggleFullScreen}
            />
            <ConditionalRender
              condition={
                viewAs === ViewSafeAs.RECIPIENT &&
                isSafeAccepted &&
                signatures?.recipientSignature?.signature &&
                signatures?.senderSignature?.signature
              }
            >
              <Button
                appearance="primary"
                className="tw-mt-24 tw-ml-auto !tw-py-14 !tw-px-40"
                width="fit"
                uppercase
                onClick={() => {
                  if (isFullScreenActive) toggleFullScreen()
                  onNextStep?.()
                }}
              >
                Next
                <SharedIcons.Arrow size={20} className="tw-ml-5" />
              </Button>
            </ConditionalRender>

            <ConditionalRender
              condition={
                viewAs === ViewSafeAs.RECIPIENT &&
                !isSafeAccepted &&
                !isSafeDeclined
              }
            >
              <Row justify="between" items="center" className="tw-mt-24">
                <Button
                  appearance="ordinary"
                  width="fit"
                  uppercase
                  onClick={onDecline}
                >
                  Decline
                </Button>
                <Button
                  width="default"
                  addonAfter={<SharedIcons.Arrow className="tw-ml-8" />}
                  uppercase
                  textClassName="tw-w-fit"
                  onClick={onAccept}
                >
                  Accept
                </Button>
              </Row>
            </ConditionalRender>
          </Col>
        </div>
      </SafeNoteContextProvider>
    </>
  )
}

export default SafeNote
