import { Nullable } from '@/common/types'
import { InviteStatus } from '@/features/auth/enums'
import { ProfileTypes } from '@/features/user/enums'
import { IFile } from '@/packages/ui'

export enum AuthProvider {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  LINKEDIN = 'linkedin',
  EMAIL = 'email'
}

export interface AuthProviderType {
  name: AuthProvider
  id?: string
}

export interface UpdateTeamMemberProps {
  fullName?: string
  position?: string
  inviteStatus?: InviteStatus
  permission?: TeamMemberPermission
}

export interface IAddress {
  address1?: Nullable<string>
  address2?: Nullable<string>
  city?: Nullable<string>
  country?: Nullable<string>
  state?: Nullable<string>
  zipCode?: Nullable<string>
}

export enum InvestorType {
  INDIVIDUAL = 'individual',
  CORPORATE = 'corporate'
}

export enum TeamMemberPermission {
  VIEW = 'view',
  CREATE = 'create'
}

export interface ITeamMember {
  readonly email: string
  readonly fullName: string
  readonly position?: string
  readonly permission: TeamMemberPermission
}

export interface UpdateUserDto extends IAddress {
  readonly fullName?: string
  readonly image?: IFile
}

export interface UpdateUserSubscription {
  readonly plan?: 'basic' | 'extend'
  readonly period?: 'month' | 'year'
}

export interface AngelProfileDataDto {
  readonly name: string
  address?: IAddress
}

export interface CorporateAngelProfileDataDto extends AngelProfileDataDto {
  readonly name: string
  readonly companyName: string
  teamMembers?: ITeamMember[]
}

export interface AgencyProfileDataDto {
  readonly name: string
  readonly linkToWebsite: string
  readonly about: string
  readonly services: string[]
  address?: IAddress
  teamMembers?: ITeamMember[]
}

export type EntrepreneurProfileDataDto = {
  name: string
  stateOfIncorporation: string
  ownerPosition: string
  teamMembers?: ITeamMember[]
}

export type EntrepreneurProfileShortDataDto = {
  name: string
  ownerPosition?: string
  teamMembers?: ITeamMember[]
}

export interface CreateNewProfileDto {
  type: ProfileTypes
  investorType?: InvestorType
  data?:
    | AngelProfileDataDto
    | CorporateAngelProfileDataDto
    | AgencyProfileDataDto
    | EntrepreneurProfileDataDto
    | EntrepreneurProfileShortDataDto
  image?: IFile
  imageUrl?: string
}

export interface CreateAngelProfileDto extends CreateNewProfileDto {
  type: ProfileTypes.ANGEL
  investorType: InvestorType.INDIVIDUAL
}

export interface CreateCorporateAngelProfileDto extends CreateNewProfileDto {
  type: ProfileTypes.ANGEL
  investorType: InvestorType.CORPORATE
  userData?: {
    fullName?: string
    image?: IFile
  }
}

export interface CreateEntrepreneurProfileDto extends CreateNewProfileDto {
  type: ProfileTypes.ENTREPRENEUR
  userData?: {
    fullName?: string
    image?: IFile
  }
}

interface IActiveSubscription {
  period: 'month' | 'year'
  plan: 'basic' | 'extend'
}

export interface IUser {
  id: string
  email: string
  emailVerified: boolean
  fullName: string
  isOnboardingComplete: boolean
  oAuthProviders: AuthProviderType[]
  image: string
  createdAt: Date
  updatedAt: Date
  activeSubscription: IActiveSubscription | null
}

export type ISimpleUser = Pick<IUser, 'id' | 'fullName' | 'email' | 'image'>

export interface IPublicUser {
  email: string
  fullName: string
  image: undefined | string
}
