import './sticky-styles.scss'

import clsx from 'clsx'
import React, { FC } from 'react'
import Sticky from 'react-stickynode'

import { SharedIcons } from '@/packages/icons'

import { DocumentControlItem } from '../DocumentControlItem'
import { DocumentSearch, DocumentSearchProps } from '../DocumentSearch'
import styles from './DocumentControl.module.scss'

interface DocumentControlProps extends DocumentSearchProps {
  fullScreenActive?: boolean
  onFullScreen?: () => void
  onDownload?: () => void
}

const DocumentControl: FC<DocumentControlProps> = ({
  onFullScreen,
  fullScreenActive,
  onDownload,
  ...rest
}: DocumentControlProps) => {
  const Content = (
    <div
      className={clsx(styles.container, 'document-control-wrapper')}
      id="document-control"
    >
      <DocumentSearch {...rest} />

      <div className={styles.right}>
        {/* <DocumentControlItem tooltipText="Share" icon={<SharedIcons.Share />} /> */}
        <DocumentControlItem
          tooltipText="Full Screen"
          icon={<SharedIcons.FullSize />}
          onClick={onFullScreen}
          active={fullScreenActive}
        />
        <DocumentControlItem
          onClick={onDownload}
          tooltipText="Download"
          icon={<SharedIcons.Download />}
        />
      </div>
    </div>
  )
  return (
    <Sticky top={20} innerZ={1} className={styles.stickyWrapper}>
      {Content}
    </Sticky>
  )
}

export default DocumentControl
