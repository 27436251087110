import { FC, useEffect, useState } from 'react'

import { ConditionalRender } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import { capitalize } from '@/common/utils'
import { permissions } from '@/features/auth/components/EntrepreneurForm/AddTeamMembers/AddTeamMembers'
import { InviteStatus } from '@/features/auth/enums'
import {
  Decline,
  Invitations,
  List
} from '@/features/dashboard/modals/InvitationsModal/parts'
import { ICompany, ICompanyTeamMember } from '@/features/profile/types'
import { Loader, Modal } from '@/packages/ui'

const MODAL_ID = 'invitations-modal'

interface InvitationsModalProps {
  companies: ICompany[]
  myId?: string
  closeModal?: (refetch?: boolean) => void
  userStep?: 'details' | 'decline'
}

const mapStateToProps = ({ user }: StoreType) => ({
  updateTeamMember: user.updateTeamMember,
  loading:
    user.functionLoading.updateTeamMember ||
    user.functionLoading.refetchCompany,
  removeCompanyFromList: user.removeCompanyFromList,
  refetchCompany: user.refetchCompany
})

const InvitationsModal: FC<
  InvitationsModalProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const {
    myId,
    companies,
    loading,
    refetchCompany,
    updateTeamMember,
    removeCompanyFromList,
    userStep
  } = props

  const [refetch, setRefetch] = useState(false)
  const [activeRequest, setActiveRequest] = useState<number>(0)
  const [step, setStep] = useState<'list' | 'details' | 'decline' | 'accept'>(
    userStep || (companies?.length > 1 ? 'list' : 'details')
  )

  useEffect(() => {
    if (userStep) {
      setStep(userStep)
    }
  }, [userStep])

  const closeModal = () => {
    // eslint-disable-next-line react/destructuring-assignment
    props.closeModal?.(refetch)
  }

  const isMulti = companies?.length > 1
  const currentRequest = companies?.[activeRequest]

  if (!Array.isArray(companies) || !currentRequest) {
    return null
  }

  const acceptedTeamMembers = currentRequest.teamMembers.filter(
    (teamMember: ICompanyTeamMember) =>
      teamMember.inviteStatus === InviteStatus.Accepted
  )
  const meAsTeamMember = currentRequest.teamMembers.find(
    (teamMember: ICompanyTeamMember) => teamMember.userId === myId
  )
  const workspace = capitalize(currentRequest.type)
  const myPermissionLabel = capitalize(
    permissions().find(
      (permission) => permission.value === meAsTeamMember?.permission
    )?.label || 'Collaborator'
  )

  const handleAccept = async (position: string) => {
    setRefetch(() => true)

    await updateTeamMember({
      data: {
        companyId: currentRequest.id,
        teamMemberId: meAsTeamMember?.userId as string,
        values: { inviteStatus: InviteStatus.Accepted, position }
      }
    })

    if (companies.length > 1) {
      await refetchCompany({ data: { id: currentRequest.id } })
      setActiveRequest(activeRequest - 1)
      setStep('list')
    } else {
      await refetchCompany({ data: { id: currentRequest.id } })
      closeModal?.()
    }
  }

  const handleDecline = async () => {
    setRefetch(() => true)
    await updateTeamMember({
      data: {
        companyId: currentRequest.id,
        teamMemberId: meAsTeamMember?.userId as string,
        values: { inviteStatus: InviteStatus.Declined }
      }
    })

    if (companies.length > 1) {
      await removeCompanyFromList(currentRequest.id)
      setActiveRequest(0)
      setStep('list')
    } else {
      await removeCompanyFromList(currentRequest.id)
      closeModal?.()
    }
  }

  const onItemAction = (index: number, type: 'details' | 'decline') => {
    setActiveRequest(index)
    setStep(type)
  }

  return (
    <Modal size="sm" id={MODAL_ID} visible setVisible={closeModal}>
      <ConditionalRender
        condition={!loading}
        fallbackElement={<Loader width="100%" height="300px" />}
      >
        {step === 'list' && (
          <List companies={companies} onItemAction={onItemAction} />
        )}

        {step === 'details' && (
          <Invitations
            isModal
            isOneOfMany={isMulti}
            onDecline={() => setStep(isMulti ? 'list' : 'decline')}
            onAccept={handleAccept}
            workspace={workspace}
            currentRequest={currentRequest}
            permissionLabel={myPermissionLabel}
            acceptedTeamMembers={acceptedTeamMembers}
          />
        )}

        {step === 'decline' && (
          <Decline
            teamMembers={acceptedTeamMembers}
            company={currentRequest}
            onFinish={handleDecline}
            onCancel={() => setStep(isMulti ? 'list' : 'details')}
          />
        )}
      </ConditionalRender>
    </Modal>
  )
}

export default withStore(mapStateToProps)(InvitationsModal)
