import { serialize } from 'object-to-formdata'

import api, { FORM_DATA_HEADERS, withParams, withQuery } from '@/common/api'
import { dataUrlToFile } from '@/common/utils'
import { PayAs } from '@/features/payment'
import { ICompany } from '@/features/profile/types'

import { CreateSafeNoteDto, ISafeNote, SignSafeDto } from './types'

export const SAFE_NOTE_ENDPOINTS = {
  CREATE: '/safe-note',
  FETCH_ALL: '/safe-note',
  FETCH_ONE: '/safe-note/:id',
  FETCH_FEE: '/safe-note/:id/fee',
  FETCH_WITH_TOKEN: '/safe-note/with-token',
  DELETE: '/safe-note/:id',
  SIGN_SAFE_NOTE: '/safe-note/:id/sign',
  NOTIFY_TO_SIGN: '/safe-note/:id/notify-to-sign',
  GENERATE_PDF: '/generate-pdf',
  PAYMENT_INTENT: '/safe-note/:id/payment-intent',
  GET_COMPANIES_SENDERS: '/safe-note/company-senders',
  FETCH_PENDING_SAFES: '/safe-note/pending-safes',
  ACCEPT_SAFE_NOTE: '/safe-note/:id/assign-company/:companyId',
  DECLINE_SAFE_NOTE: '/safe-note/:id/decline',
  GENERATE_PDFS: '/company/:id/safes-files',
  PAY_FOR_SAFE: '/safe-note/:id/plaid',
  ADD_PAYMENT_RECORD: '/safe-note/:id/receipt'
}

export class SafeNoteRequests {
  static async create(dto: CreateSafeNoteDto): Promise<ISafeNote> {
    const { recipients, ...rest } = dto

    const formData = serialize(rest)
    formData.delete('senderSignature')

    if (dto.mfn === true) {
      formData.delete('discountRate')
      formData.delete('valuationCap')
    }

    const signature = await dataUrlToFile(
      dto.senderSignature,
      'senderSignature'
    )

    formData.set('senderSignature', signature)

    recipients.forEach((item, index) => {
      Object.entries(item).forEach(([key, value]) => {
        formData.set(`recipients[${index}][${key}]`, value)
      })
    })

    return api.post(SAFE_NOTE_ENDPOINTS.CREATE, formData, {
      headers: FORM_DATA_HEADERS
    })
  }

  static async fetchAll(
    entrepreneurCompanyId?: string,
    angelCompanyId?: string,
    additionalQuery?: any
  ): Promise<ISafeNote[]> {
    const query: { entrepreneurCompanyId?: string; angelCompanyId?: string } =
      {}

    if (!!entrepreneurCompanyId) {
      query.entrepreneurCompanyId = entrepreneurCompanyId
    }

    if (!!angelCompanyId) {
      query.angelCompanyId = angelCompanyId
    }

    if (!!additionalQuery) {
      Object.assign(query, additionalQuery)
    }

    return api.get(withQuery(SAFE_NOTE_ENDPOINTS.FETCH_ALL, query))
  }

  static async fetchPendingSafes(): Promise<ISafeNote[]> {
    return api.get(SAFE_NOTE_ENDPOINTS.FETCH_PENDING_SAFES)
  }

  static async acceptSafeNote(id: string, companyId: string): Promise<boolean> {
    return api.post(
      withParams(SAFE_NOTE_ENDPOINTS.ACCEPT_SAFE_NOTE, { id, companyId })
    )
  }

  static async declineSafeNote(id: string): Promise<boolean> {
    return api.patch(withParams(SAFE_NOTE_ENDPOINTS.DECLINE_SAFE_NOTE, { id }))
  }

  static async fetchOne(id: string): Promise<ISafeNote> {
    return api.get(withParams(SAFE_NOTE_ENDPOINTS.FETCH_ONE, { id: id.trim() }))
  }

  static async fetchCompaniesSenders(
    angelCompanyId: string
  ): Promise<ICompany[]> {
    return api.get(
      withQuery(SAFE_NOTE_ENDPOINTS.GET_COMPANIES_SENDERS, { angelCompanyId })
    )
  }

  static async fetchFee(id: string): Promise<any> {
    return api.get(withParams(SAFE_NOTE_ENDPOINTS.FETCH_FEE, { id: id.trim() }))
  }

  static async fetchWithToken(token: string): Promise<ISafeNote> {
    return api.get(withQuery(SAFE_NOTE_ENDPOINTS.FETCH_WITH_TOKEN, { token }))
  }

  static async delete(id: string, body?: unknown): Promise<void> {
    return api.delete(withParams(SAFE_NOTE_ENDPOINTS.DELETE, { id }), {
      data: body
    })
  }

  static async sign(id: string, dto: SignSafeDto) {
    const formData = new FormData()

    const signature = await dataUrlToFile(dto.signature, 'signature.png')

    formData.set('signature', signature)
    formData.set('signAs', dto.signAs)
    formData.set('name', dto.name)

    return api.post(
      withParams(SAFE_NOTE_ENDPOINTS.SIGN_SAFE_NOTE, { id }),
      formData,
      {
        headers: FORM_DATA_HEADERS
      }
    )
  }

  static notifyToSign(id: string, reminderMessage?: string) {
    return api.post(withParams(SAFE_NOTE_ENDPOINTS.NOTIFY_TO_SIGN, { id }), {
      reminderMessage
    })
  }

  static downloadZip(companyId: string, safeNoteIds: string[]) {
    return api.get(
      withQuery(
        withParams(SAFE_NOTE_ENDPOINTS.GENERATE_PDFS, { id: companyId }),
        {
          safeNoteIds: safeNoteIds.toString()
        }
      ),
      { responseType: 'arraybuffer' }
    )
  }

  static generatePdf(html: string) {
    return api.post(
      SAFE_NOTE_ENDPOINTS.GENERATE_PDF,
      { html },
      { responseType: 'blob' }
    )
  }

  static payForSafe(safeNoteId: string, accountId: string, amount: number) {
    return api.post(
      withParams(SAFE_NOTE_ENDPOINTS.PAY_FOR_SAFE, { id: safeNoteId }),
      { payAs: PayAs.ANGEL, accountId, amount }
    )
  }

  static addPaymentRecord(safeNoteId: string, amount: number) {
    return api.post(
      withParams(SAFE_NOTE_ENDPOINTS.ADD_PAYMENT_RECORD, { id: safeNoteId }),
      { payAs: PayAs.ANGEL, amount }
    )
  }
}
