import { FC, useCallback, useEffect, useRef } from 'react'

import { NotificationRequests } from '../api/NotificationRequests'
import { Notification } from '../api/types'
import IncomingSafeNote from './Types/IncomingSafeNote'
import SignedSafeNote from './Types/SignedSafeNote'
import PayedSafeNote from './Types/PayedSafeNote'
import TeamMemberRequest from './Types/TeamMemberRequest'

interface NotificationsListProps {
  notifications: Notification[]
  className?: string
  updateNotificationCount?: (count: number) => void
  setVisible?: (visible: boolean) => void
}

const NotificationsList: FC<NotificationsListProps> = ({
  notifications,
  className,
  updateNotificationCount,
  setVisible
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const markRead = useCallback((ids: string[]) => {
    if (ids.length) NotificationRequests.markReadNotifications(ids)
  }, [])

  const handleIntersect: IntersectionObserverCallback = useCallback(
    (entries, observer) => {
      const unreadNotificationIds = entries
        .filter(
          (entry) =>
            entry.isIntersecting &&
            entry.target.getAttribute('data-isread') === 'false'
        )
        .map((entry) => {
          const id = entry.target.getAttribute('id')
          entry.target.classList.add('tw-bg-[#EFF4FF]')
          observer.unobserve(entry.target)
          return id as string
        })

      markRead(unreadNotificationIds)

      if (unreadNotificationIds.length) {
        updateNotificationCount?.(unreadNotificationIds.length)
      }
    },
    [markRead]
  )

  const notificationComponents = {
    signed_safe_note: SignedSafeNote,
    team_member_request: TeamMemberRequest,
    incoming_safe_note: IncomingSafeNote,
    payed_safe_note: PayedSafeNote
  }

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersect, {
      threshold: 0.1
    })
    const elements =
      containerRef.current?.querySelectorAll('.notification-item') || []

    elements.forEach((element) => observer.observe(element))

    return () => {
      elements.forEach((element) => observer.unobserve(element))
    }
  }, [notifications, handleIntersect])

  return (
    <div ref={containerRef} className={className}>
      {notifications.map((notification) => {
        const NotificationComponent = notificationComponents[notification.type]

        return NotificationComponent ? (
          <NotificationComponent
            key={notification.id}
            // @ts-ignore
            notification={notification}
            setVisible={setVisible}
          />
        ) : <p>{notification.type}</p>
      })}
    </div>
  )
}

export default NotificationsList
