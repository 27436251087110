import { noop } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { StoreType, withStore } from '@/common/store'
import {
  ISafeNote,
  ISafeNoteHighlights,
  NotAuthorizedHeader,
  SafeNote,
  Terms,
  ViewSafeAs
} from '@/features/safe-note'
import { useSmartState } from '@/packages/hooks'
import { FullScreenLoading } from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './PreviewSafe.module.scss'

const mapStateToProps = ({ safeNote }: StoreType) => ({
  fetchWithToken: safeNote.fetchWithToken,
  generatePdf: safeNote.generatePdf,
  loading: safeNote.functionLoading.fetchOne
})

type PreviewSafeProps = ReturnType<typeof mapStateToProps>

const PreviewSafe: FC<PreviewSafeProps> = ({
  fetchWithToken,
  loading
}: PreviewSafeProps) => {
  const navigate = useNavigator()
  const [searchParams] = useSearchParams()
  const [safeNote, setSafeNote] = useState<ISafeNote | undefined>(undefined)

  const [highlights, setHighlights] = useSmartState<ISafeNoteHighlights>({
    mfn: false,
    helpSetTerms: false,
    discountRate: false,
    valuationCap: false
  })

  const token = searchParams.get('token')

  const runFetchSafeNote = async () => {
    if (!token) return

    try {
      const data: ISafeNote = await fetchWithToken({ data: { token } })
      setSafeNote(data)
    } catch (err) {
      navigate.toDashboard()
    }
  }

  useEffect(() => {
    if (token) {
      runFetchSafeNote()
    }
  }, [token])

  if (!safeNote || loading) {
    return <FullScreenLoading loading solidColor />
  }

  const handleDeclineSafe = () => {
    if (token) {
      navigate.toDashboard()
    }
  }

  const handleAcceptSafe = () => {
    if (token) {
      navigate.toDashboard()
    }
  }

  const SafeNoteTabContent = (
    <SafeNote
      {...safeNote}
      // for not signed-in users, viewAs is always recipient
      viewAs={ViewSafeAs.RECIPIENT}
      safeNoteId={safeNote.id}
      sender={safeNote.sender}
      recipient={safeNote.recipient}
      companyName={safeNote.senderCompany.name}
      stateOfIncorporation={safeNote.senderCompany.stateOfIncorporation || ''}
      recipients={[
        {
          name: safeNote.recipient.fullName,
          email: safeNote.recipient.email,
          img: safeNote.recipient.image,
          amount: safeNote.safeAmount
        }
      ]}
      mfn={safeNote.mfn}
      //
      selectedTermsId={safeNote.mfn ? Terms.MFN : Terms.HELP_SET_TERMS}
      discountRate={safeNote.discountRate}
      discountRateActive={!!safeNote.discountRate}
      valuationCap={safeNote.valuationCap}
      valuationCapActive={!!safeNote.valuationCap}
      //
      signatures={{
        senderSignatureName: safeNote.senderSignName,
        senderSignatureDate: safeNote.senderSignDate,
        senderSignature: { signature: safeNote.senderSignature },

        recipientSignature: { signature: safeNote.recipientSignature },
        recipientSignatureDate: safeNote.recipientSignDate || new Date(),
        recipientSignatureName:
          safeNote.recipientSignName || safeNote.recipient.fullName
      }}
      viewMode
      onUpdateData={noop}
      highlights={highlights}
      updateHighlights={setHighlights}
      onAccept={handleAcceptSafe}
      onDecline={handleDeclineSafe}
    />
  )

  return (
    <div className={styles.container}>
      <NotAuthorizedHeader token={token} />
      <div className={styles.content}>
        <div className={styles.wrapper}>{safeNote && SafeNoteTabContent}</div>
      </div>
      <NotAuthorizedHeader token={token} withoutLogo />
    </div>
  )
}

export default withStore(mapStateToProps)(PreviewSafe)
