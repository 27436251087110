import { AxiosResponse } from 'axios'

import api, { withQuery } from '@/common/api'

import { Notification } from './types'

const NOTIFICATION_ENDPOINTS = {
  NOTIFICATIONS: '/notification',
  NOTIFICATIONS_COUNT: '/notification/count'
}

export class NotificationRequests {
  static fetchGlobalNotificationsCount(
    unread: boolean
  ): Promise<AxiosResponse<number>> {
    return api.get(
      withQuery(NOTIFICATION_ENDPOINTS.NOTIFICATIONS_COUNT, {
        ...(unread && { read: 'false' })
      })
    )
  }

  static fetchGlobalNotifications(
    unread: boolean
  ): Promise<AxiosResponse<Notification[]>> {
    return api.get(
      withQuery(NOTIFICATION_ENDPOINTS.NOTIFICATIONS, {
        ...(unread && { read: 'false' })
      })
    )
  }

  static fetchCompanyNotificationsCount(
    companyId: string,
    unread: boolean
  ): Promise<AxiosResponse<number>> {
    return api.get(
      withQuery(NOTIFICATION_ENDPOINTS.NOTIFICATIONS_COUNT, {
        companyId,
        ...(unread && { read: 'false' })
      })
    )
  }

  static fetchCompanyNotifications(
    companyId: string,
    unread: boolean
  ): Promise<AxiosResponse<Notification[]>> {
    return api.get(
      withQuery(NOTIFICATION_ENDPOINTS.NOTIFICATIONS, {
        companyId,
        ...(unread && { read: 'false' })
      })
    )
  }

  static markReadNotifications(ids: string[]): Promise<AxiosResponse> {
    return api.patch(NOTIFICATION_ENDPOINTS.NOTIFICATIONS, { ids })
  }
}
