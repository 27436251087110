import clsx from 'clsx'
import { useState } from 'react'

import { DateHelper } from '@/common/helpers'
import { StoreType, withStore } from '@/common/store'
import { InvitationsModal } from '@/features/dashboard/modals'
import { useModal } from '@/packages/hooks'
import { Color } from '@/packages/palette'
import { Button, Col, FontWeight, Row, Text, TextTypes } from '@/packages/ui'

interface TeamMemberRequestPayload {
  inviterName: string
  inviterImage: string
  companyName: string
  companyId: string
  companyImage: string
}

interface TeamMemberRequestNotification {
  id: string
  userId: string
  companyId: string | null
  message: string | null
  type: string
  payload: TeamMemberRequestPayload
  read: boolean
  createdAt: string
  updatedAt: string
}

const mapStateToProps = ({ user }: StoreType) => ({
  companiesList: user.sortedCompanies,
  myId: user.me?.id
})
interface TeamMemberRequestProps extends ReturnType<typeof mapStateToProps> {
  notification: TeamMemberRequestNotification
  setVisible: (visible: boolean) => void
}

const TeamMemberRequest = ({
  notification,
  myId,
  companiesList,
  setVisible
}: TeamMemberRequestProps) => {
  const { visible, closeModal, openModal } = useModal<'invites'>()
  const [userStep, setUserStep] = useState<string>('')

  const closeInvitesModal = () => {
    closeModal()
  }

  const requests = companiesList.requests.filter(
    (company) => company.id === notification.payload.companyId
  )

  const isRequestExists = companiesList.requests.some(
    (company) => company.id === notification.payload.companyId
  )

  const handleAccept = () => {
    setUserStep('details')
    setVisible(false)
    openModal('invites')
  }

  const handleDecline = () => {
    setUserStep('decline')
    setVisible(false)
    openModal('invites')
  }

  return (
    <div
      id={notification.id}
      data-isRead={notification.read}
      className="tw-w-full notification-item"
    >
      {visible === 'invites' && (
        <InvitationsModal
          companies={requests}
          myId={myId}
          closeModal={closeInvitesModal}
          userStep={userStep}
        />
      )}
      <Row gap={8}>
        <img
          src={notification.payload.inviterImage}
          width={32}
          height={32}
          className="tw-rounded-full"
          alt={notification.payload.companyName}
          draggable="false"
        />
        <Col className="tw-w-full" gap={2}>
          <div className="tw-inline-block">
            <Text
              type={TextTypes.BODY_DEFAULT}
              className={clsx(FontWeight.SEMIBOLD, 'tw-me-4')}
            >
              {notification.payload.inviterName}
            </Text>
            invited you to become a team member of his company
            <Row items="center" gap={4} className="!tw-w-fit">
              <img
                className="tw-rounded-full"
                draggable="false"
                width={18}
                height={18}
                src={notification.payload.companyImage}
                alt={notification.payload.companyName}
              />
              <Text
                asSpan
                weight={FontWeight.SEMIBOLD}
                color={Color.primary500}
              >
                {notification.payload.companyName}
              </Text>
            </Row>
          </div>

          {isRequestExists && (
            <Row items="center" gap={6} className="tw-mt-14">
              <Button
                size="sm"
                type="button"
                width="full"
                uppercase
                disabled={!isRequestExists}
                onClick={handleAccept}
              >
                Accept
              </Button>
              <Button
                size="sm"
                type="button"
                appearance="secondary"
                width="full"
                uppercase
                disabled={!isRequestExists}
                onClick={handleDecline}
              >
                Decline
              </Button>
            </Row>
          )}

          <Text color={Color.neutral300} className="tw-mt-8">
            {DateHelper.formatIsoDate(notification.createdAt)}
          </Text>
        </Col>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
            stroke="#618CFA"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.75 12L10.58 14.83L16.25 9.17004"
            stroke="#618CFA"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </Row>
    </div>
  )
}

export default withStore(mapStateToProps)(TeamMemberRequest)
