import React, { FC, useMemo } from 'react'

import { ConditionalRender, OptionsPopup } from '@/common/components'
import { IPopupOption } from '@/common/components/OptionsPopup/OptionsPopup'
import { StoreType, withStore } from '@/common/store'
import {
  DeleteCompanyModal,
  EditCompanyModal
} from '@/features/dashboard/modals'
import { ICompany } from '@/features/profile/types'
import { getPermission } from '@/features/profile/utils'
import { TeamMemberPermission } from '@/features/user'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'

import styles from './CompanyOptions.module.scss'

interface CompanyOptionsProps {
  company: ICompany
  myId: string
}

const mapStateToProps = ({ user }: StoreType) => ({
  myId: user.me?.id
})

const CompanyOptions: FC<
  CompanyOptionsProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const { company, myId } = props
  const { visible, openModal, closeModal } = useModal<'delete' | 'edit'>()

  const permission = useMemo(
    () => getPermission(myId, company),
    [myId, company]
  )

  const isCreator = permission === TeamMemberPermission.CREATE

  if (!isCreator) return null

  const options: IPopupOption[] = [
    {
      id: 'edit',
      label: 'Edit',
      icon: <SharedIcons.Pencil color={Color.neutral400} size={18} />
    },
    {
      id: 'delete',
      label: 'Delete',
      icon: <SharedIcons.Trash color={Color.neutral400} size={20} />
    }
    // ...(company.type === ProfileTypes.ENTREPRENEUR
    //   ? [
    //       {
    //         id: 'verify',
    //         label: 'Verify company',
    //         icon: <VerificationMark color={Color.neutral400} size={18} />
    //       }
    //     ]
    //   : [])
  ]

  const onOptionClick = (option: string) => {
    if (option === 'delete') {
      openModal('delete')
    } else {
      openModal('edit')
    }
  }

  const PopupInner = (
    <div
      className={styles.iconWrapper}
      onClick={(event) => event.stopPropagation()}
    >
      <SharedIcons.Dots color={Color.neutral400} className={styles.more} />
    </div>
  )

  return (
    <>
      {visible === 'delete' && (
        <DeleteCompanyModal company={company} closeModal={closeModal} />
      )}

      {visible === 'edit' && (
        <EditCompanyModal company={company} closeModal={closeModal} />
      )}

      <ConditionalRender condition={!visible} fallbackElement={PopupInner}>
        <OptionsPopup options={options} onOptionClick={onOptionClick}>
          {PopupInner}
        </OptionsPopup>
      </ConditionalRender>
    </>
  )
}

export default withStore(mapStateToProps)(CompanyOptions)
