import clsx from 'clsx'
import { noop } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  AnchorLink,
  ConditionalRender,
  HeaderBar,
  OptionsPopup
} from '@/common/components'
import { useToast } from '@/common/hooks'
import { StoreType, withStore } from '@/common/store'
import { AddNewProfileModal } from '@/features/dashboard/modals'
import {
  ISafeNote,
  ISafeNoteDraft,
  ISafeNoteHighlights,
  ISafeNoteSignatures,
  PaymentStep,
  SAFE_NOTE_TEMPLATE_ANCHORS,
  SafeNote,
  SafeNoteStatus,
  SignSafeAs,
  Terms,
  ViewSafeAs
} from '@/features/safe-note'
import { FullScreenContextProvider } from '@/features/safe-note/context/FullScreenContext'
import { SafePaymentSummary } from '@/features/safe-note/components/SafePaymentSummary'
import styles from '@/features/safe-note/layouts/CreateSafeNoteLayout/CreateSafeNoteLayout.module.scss'
import { AcceptSafeModal, DeclineSafeModal } from '@/features/safe-note/modals'
import { ProfileTypes } from '@/features/user/enums'
import { useModal, useSmartState } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  Col,
  FontWeight,
  FullScreenLoading,
  Heading,
  HeadingTypes,
  Row,
  StepperNavigation,
  Text,
  TextTypes,
  useStepper
} from '@/packages/ui'
import { IStep } from '@/packages/ui/stepper/types'
import { useNavigator } from '@/router'

const mapStateToProps = ({ safeNote, user, payment }: StoreType) => ({
  fetchOne: safeNote.fetchOne,
  signSafe: safeNote.sign,
  loading: safeNote.functionLoading.fetchOne,
  me: user.me
})

const defaultSignaturesValue: ISafeNoteSignatures = {
  senderSignature: undefined,
  senderSignatureName: undefined,
  senderSignatureDate: undefined,
  recipientSignature: undefined,
  recipientSignatureName: undefined,
  recipientSignatureDate: undefined
}

const options = [
  { id: 'sendReminder', label: 'Send reminder' },
  { id: 'deleteSafe', label: 'Delete SAFE' }
]

const angelSteps: IStep[] = [
  {
    id: 'reviewAndSign',
    title: 'Review and Sign'
  },
  {
    id: 'pay',
    title: 'Make Investment'
  }
]

const ViewSafeNew: FC<ReturnType<typeof mapStateToProps>> = (props) => {
  const { fetchOne, loading, me, signSafe } = props

  const { id } = useParams()
  const navigate = useNavigator()
  const { showWarning } = useToast()

  const acceptSafeModal = useModal(false)
  const createProfileModal = useModal(false)
  const declineSafeModal = useModal(false)

  const stepper = useStepper(0, angelSteps.length)

  const [isFullScreenActive, setFullScreenActive] = useState<boolean>(false)
  const [safeNote, setSafeNote] = useState<ISafeNote | undefined>()
  const [signatures, setSignatures] = useState<ISafeNoteSignatures>(
    () => defaultSignaturesValue
  )
  const [nextDisabled, setNextDisabled] = useState(
    !!signatures.recipientSignatureDate
  )
  const [highlights, setHighlights] = useSmartState<ISafeNoteHighlights>({
    mfn: false,
    helpSetTerms: false,
    discountRate: false,
    valuationCap: false
  })

  // util variables
  const viewAsRecipient = safeNote?.viewAs === ViewSafeAs.RECIPIENT
  const viewAsSender = safeNote?.viewAs === ViewSafeAs.SENDER
  const isSafeAccepted = !!safeNote?.recipientCompanyId
  const isSafeDeclined = safeNote?.status === SafeNoteStatus.DECLINED

  const fetchSafeNote = async () => {
    if (!id) {
      navigate.toDashboard()
      return
    }

    const response = await fetchOne({
      data: {
        id
      },
      options: {
        onError: () => {
          navigate.toDashboard()
        }
      }
    })

    setSafeNote(response?.id ? response : undefined)

    setSignatures(
      response?.id
        ? {
            senderSignatureName: response.senderSignName,
            senderSignatureDate: response.senderSignDate,
            senderSignature: { signature: response.senderSignature },

            recipientSignature: { signature: response.recipientSignature },
            recipientSignatureDate: response.recipientSignDate,
            recipientSignatureName:
              response.recipientSigneName || response.recipient.fullName
          }
        : defaultSignaturesValue
    )
  }

  useEffect(() => {
    fetchSafeNote()
  }, [id])

  const handleUpdateData = (data: Partial<ISafeNoteDraft>) => {
    if (data.signatures) {
      setSignatures(data.signatures)
      setNextDisabled(false)
    }
  }

  const handleDeclineSafe = () => {
    if (me?.id && !me?.emailVerified) {
      showWarning('You cannot decline the SAFE until verify your email!')
      return
    }

    declineSafeModal.openModal()
  }

  const handleAcceptSafe = () => {
    if (me?.id && !me?.emailVerified) {
      showWarning('You cannot accept the SAFE until verify your email!')
      return
    }

    acceptSafeModal.openModal()
  }

  const handlePrevStepAngelProcess = () => {
    stepper.prevStep()
    setNextDisabled(false)
  }

  const handleNextStepAngelProcess = () => {
    stepper.nextStep()

    if(stepper.currentStep === 0) {
      setNextDisabled(true)
    }

    if (
      stepper.currentStep === 0 &&
      viewAsRecipient &&
      !safeNote?.recipientSignDate
    ) {
      // sign safe as recipient
      signSafe({
        data: {
          id: safeNote.id,
          name: signatures.recipientSignatureName!,
          signAs: SignSafeAs.RECIPIENT,
          signature: signatures.recipientSignature?.signature
        }
      })
    }
  }

  const SenderHeaderBar = (
    <HeaderBar
      middleComponent={
        <Heading
          type={HeadingTypes.H3}
          weight={FontWeight.BOLD}
          color={Color.neutral400}
        >
          View SAFE
        </Heading>
      }
      rightSide={
        <OptionsPopup options={options} onOptionClick={noop}>
          <div className={styles.optionsWrapper}>
            <SharedIcons.Dots color={Color.neutral400} />
          </div>
        </OptionsPopup>
      }
      withExit
      onExit={() => navigate.toDashboard()}
    />
  )

  const RecipientPendingSafeHeader = (
    <HeaderBar
      rightSide={
        <Row gap={12}>
          <Button
            width="default"
            appearance="secondary"
            className="!tw-w-[154px]"
            onClick={handleDeclineSafe}
          >
            Decline
          </Button>
          <Button
            className="!tw-w-[154px]"
            addonAfter={<SharedIcons.Arrow className="tw-ml-8" size={20} />}
            onClick={handleAcceptSafe}
          >
            Accept
          </Button>
        </Row>
      }
      withExit
      onExit={() => navigate.toDashboard()}
    />
  )

  const Stepper = (
    <div className={styles.stepperWrapper}>
      <StepperNavigation
        type="small"
        activeStep={stepper.currentStep}
        className={styles.navigation}
        steps={angelSteps}
        itemTextType={TextTypes.BODY_DEFAULT}
        markPreviousAsCompleted={false}
        allowManuallyChangeStep
      />
    </div>
  )

  const AngelProcessNavigation = (
    <Row items="stretch" gap={12}>
      {stepper.currentStep > 0 && (
        <div>
          <Button
            uppercase
            width="fit"
            appearance="secondary"
            disabled={loading}
            onClick={handlePrevStepAngelProcess}
            className={styles.backButton}
          >
            <SharedIcons.Arrow size={20} color={Color.neutral300} />
          </Button>
        </div>
      )}

      <Button
        uppercase
        width="fit"
        onClick={handleNextStepAngelProcess}
        // disabled={!signatures.recipientSignatureDate || loading}
        disabled={nextDisabled}
        className="!tw-py-14 !tw-px-40"
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {loading
          ? 'Loading...'
          : stepper.isLastStep
          ? 'Complete Investment '
          : 'Next'}
        {!stepper.isLastStep && (
          <SharedIcons.Arrow size={20} className="tw-ml-5" />
        )}
      </Button>
    </Row>
  )

  const RecipientAcceptedSafeHeader = (
    <HeaderBar
      middleComponent={Stepper}
      rightSide={AngelProcessNavigation}
      withExit
      onExit={() => navigate.toDashboard()}
    />
  )

  const AcceptedSafeBanner = (
    <Row items="center" justify="center" className={styles.acceptedBanner}>
      <SharedIcons.Warning
        size={14}
        color={Color.success400}
        className="tw-mr-8"
      />
      <Text
        type={TextTypes.BODY_SMALL}
        color={Color.success400}
        weight={FontWeight.SEMIBOLD}
      >
        You’ve accepted the SAFE from {safeNote?.sender?.fullName} @{' '}
        {safeNote?.senderCompany?.name}!
      </Text>
    </Row>
  )

  const DeclinedSafeBanner = (
    <Row items="center" justify="center" className={styles.declinedBanner}>
      <SharedIcons.Warning
        size={14}
        color={Color.error300}
        className="tw-mr-8"
      />
      <Text
        type={TextTypes.BODY_SMALL}
        color={Color.error300}
        weight={FontWeight.SEMIBOLD}
      >
        You’ve declined the SAFE from {safeNote?.sender?.fullName} @{' '}
        {safeNote?.senderCompany?.name}!
      </Text>
    </Row>
  )

  const renderRecipientSafeTitle = () => {
    let title = `SAFE note (${safeNote?.senderCompany.name})`
    let description = 'View a summary of the SAFE terms and accept or decline.'

    if (stepper.currentStep === 0 && safeNote?.recipientCompanyId) {
      title = 'Add you signature'

      return (
        <Col items="center" className="tw-mb-24">
          <Heading type={HeadingTypes.H2}>{title}</Heading>
          <Text color={Color.neutral400} className="tw-mt-8">
            To make an investment&nbsp;
            <AnchorLink to={SAFE_NOTE_TEMPLATE_ANCHORS.SIGNATURES}>
              <Text weight={FontWeight.SEMIBOLD} color={Color.primary400}>
                add your signature
              </Text>
            </AnchorLink>
            &nbsp;at the end of the document.
          </Text>
        </Col>
      )
    }

    if (stepper.currentStep === 1) {
      title = 'Complete your investment'
      description = 'Select how you want to make an investment.'
    }

    return (
      <Col items="center" className="tw-mb-24">
        <Heading type={HeadingTypes.H2}>{title}</Heading>
        <Text color={Color.neutral400} className="tw-mt-8">
          {description}
        </Text>
      </Col>
    )
  }

  const RecipientHeaderBar = (() => {
    if (isSafeAccepted) {
      return RecipientAcceptedSafeHeader
    }

    if (isSafeDeclined) {
      return DeclinedSafeBanner
    }

    return RecipientPendingSafeHeader
  })()

  if (loading || !safeNote) return <FullScreenLoading loading />

  if (stepper.completed) return <SafePaymentSummary safeNote={safeNote} />

  return (
    <FullScreenContextProvider
      active={isFullScreenActive}
      setActive={setFullScreenActive}
    >
      <div className={clsx(styles.wrapper, styles.wide)}>
        <ConditionalRender condition={declineSafeModal.visible}>
          <DeclineSafeModal
            closeModal={declineSafeModal.closeModal}
            safeNote={safeNote}
            onDecline={navigate.toDashboard}
          />
        </ConditionalRender>

        <ConditionalRender condition={acceptSafeModal.visible}>
          <AcceptSafeModal
            closeModal={acceptSafeModal.closeModal}
            onAccepted={fetchSafeNote}
            safeNote={safeNote}
            toCreateCompany={createProfileModal.openModal}
          />
        </ConditionalRender>

        <ConditionalRender condition={createProfileModal.visible}>
          <AddNewProfileModal
            predefinedRole={ProfileTypes.ANGEL}
            closeModal={createProfileModal.closeModal}
          />
        </ConditionalRender>

        {!isFullScreenActive &&
          (viewAsSender ? SenderHeaderBar : RecipientHeaderBar)}
        {!isFullScreenActive &&
          viewAsRecipient &&
          isSafeAccepted &&
          AcceptedSafeBanner}

        <div className={styles.container}>
          <div className={clsx(!isFullScreenActive && styles.containerInner)}>
            {!isFullScreenActive &&
              viewAsRecipient &&
              renderRecipientSafeTitle()}

            {stepper.currentStep === 0 && (
              <SafeNote
                safeNoteId={id}
                sender={safeNote.sender}
                recipient={safeNote.recipient}
                companyName={safeNote.senderCompany.name}
                stateOfIncorporation={
                  safeNote.senderCompany.stateOfIncorporation || ''
                }
                recipients={[
                  {
                    name: safeNote.recipient.fullName,
                    email: safeNote.recipient.email,
                    img: safeNote.recipient.image,
                    amount: safeNote.safeAmount
                  }
                ]}
                mfn={safeNote.mfn}
                //
                selectedTermsId={
                  safeNote.mfn ? Terms.MFN : Terms.HELP_SET_TERMS
                }
                discountRate={safeNote.discountRate}
                discountRateActive={!!safeNote.discountRate}
                valuationCap={safeNote.valuationCap}
                valuationCapActive={!!safeNote.valuationCap}
                //
                viewMode
                viewAs={safeNote.viewAs}
                onUpdateData={handleUpdateData}
                highlights={highlights}
                updateHighlights={setHighlights}
                //
                signatures={signatures}
                isSafeAccepted={!!safeNote.recipientCompanyId}
                isSafeDeclined={isSafeDeclined}
                isSignatureAdded={
                  (viewAsSender && !!safeNote?.senderSignDate) ||
                  (viewAsRecipient && !!safeNote?.recipientSignDate)
                }
                onAccept={handleAcceptSafe}
                onDecline={handleDeclineSafe}
                onNextStep={handleNextStepAngelProcess}
              />
            )}

            {stepper.currentStep === 1 && <PaymentStep safeNote={safeNote} onPaymentComplete={stepper.nextStep} setNextDisabled={setNextDisabled} />}
          </div>
        </div>
      </div>
    </FullScreenContextProvider>
  )
}

export default withStore(mapStateToProps)(ViewSafeNew)
