import clsx from 'clsx'
import Lottie from 'lottie-react'
import React, { FC, ReactNode } from 'react'

import { LottieConfetti } from '@/assets/lottie'
import { HeaderBar } from '@/common/components'
import { useStore } from '@/common/store'
import { toCurrencyFormat } from '@/common/utils'
import { ISafeNote, SAFE_NOTE_TEMPLATE_ANCHORS, SAFE_NOTE_TEMPLATE_ID, SafeNoteTemplate } from '@/features/safe-note'
import starsImg from '@/features/safe-note/assets/images/stars.png'
import transferArrowsImg from '@/features/safe-note/assets/images/transfer-arrows.svg'
import { getRawSafeTemplate } from '@/features/safe-note/utils/raw-template'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Button,
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './SafePaymentSummary.module.scss'

interface PaymentSummaryProps {
  safeNote: ISafeNote,
}

const SafePaymentSummary: FC<PaymentSummaryProps> = (props) => {
  const { safeNote } = props

  const navigate = useNavigator()

  const generatePdf = useStore((store) => store.safeNote.generatePdf)



  const handleViewClick = () => {
    navigate.toViewSafeNote(safeNote.id, {
      openInNewTab: true,
    })
  }

  const handleBackClick = () => {
    navigate.toDashboard()
  }

  const handleDownload = () => {
    const template = getRawSafeTemplate()
    const fileName = `SAFE_${safeNote.recipient.fullName}`

    generatePdf({
      data: {
        html: template,
        fileName
      }
    })
  }

  const PreviewSave= (
    <div className={clsx(styles.blueBox, 'tw-flex tw-justify-between tw-items-center')}>
      <div className="tw-flex tw-items-center tw-gap-8">
        <div className={clsx(styles.receipt)}>
          <SharedIcons.Receipt size={20} color={Color.neutral400} />
        </div>
        View Executed SAFE
      </div>

      <Button
        className=""
        appearance="ordinary"
        width="fit"
        addonBefore={<SharedIcons.DownloadModern color={Color.primary400} size={20} className="tw-mr-2" />}
        onClick={handleDownload}
      >
        Download
      </Button>
    </div>
  )

  const renderTermsItem = (title: string, value: ReactNode) => (
    <Row gap={20} justify="between" items="center" className={styles.whiteBox}>
      <Text
        type={TextTypes.BODY_DEFAULT}
        weight={FontWeight.MEDIUM}
        color={Color.neutral400}
      >
        {title}
      </Text>

      {value}
    </Row>
  )
  
  const AvatarItem = (userImg: string, companyImg: string) => (
    <div className="tw-relative">
      <img
        src={userImg}
        alt="user avatar"
        width={100}
        className={clsx(styles.avatar)}
      />
      <img
        src={companyImg}
        alt="company image"
        width={35}
        className={clsx(styles.subAvatar)}
      />
    </div>
  )

  const relationsPreviewItem = (
    <div>
      <img
        src={transferArrowsImg}
        alt="transfer arrows"
        width={100}
      />
      <div className={clsx(styles.composition, 'tw-gap-37 tw-mb-30')}>
        <img
          src={starsImg}
          alt="stars"
          className="tw-aspect-square"
          width={100}
        />
        <div className={clsx(styles.composition, 'tw-gap-20')}>
          {AvatarItem(
            safeNote.recipient.image,
            safeNote.recipientCompany?.image as string
          )}
          {AvatarItem(safeNote.sender.image, safeNote.senderCompany.image as string)}
        </div>
        <img
          src={starsImg}
          alt="stars"
          className={clsx(styles.reverse, 'tw-aspect-square')}
          width={100}
        />
      </div>
    </div>
  )

  return (
    <div className={styles.summaryScreen}>
      <Lottie animationData={LottieConfetti} className={styles.leftConfetti} />

      <Lottie animationData={LottieConfetti} className={styles.rightConfetti} />

      <HeaderBar />

      <div className={styles.summaryScreenInner}>
        <div className={styles.content}>
          {relationsPreviewItem}

          <Heading
            type={HeadingTypes.H2}
            align={TextAlign.CENTER}
            color={Color.neutral500}
          >
            Congratulations on your investment!
          </Heading>
          <Text
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral300}
            align={TextAlign.CENTER}
            className="tw-mt-8"
          >
            Review your investment details, and download the SAFE and receipt
            for your records below.
          </Text>

          <Col gap={12} items="stretch" className="tw-w-full tw-mt-24">
            {PreviewSave}

            {renderTermsItem(
              'Company',
              <div className="tw-font-medium">
                {safeNote.senderCompany.name}
              </div>
            )}
            {renderTermsItem(
              'Investment amount:',
              <Badge type={BadgeType.INFO}>
                ${toCurrencyFormat(safeNote.safeAmount)}
              </Badge>
            )}

            {!safeNote.mfn && (
              <>
                {!!safeNote.discountRate &&
                  renderTermsItem(
                    'Discount:',
                    <Badge type={BadgeType.SUCCESS}>
                      {safeNote.discountRate}%
                    </Badge>
                  )}
                {!!safeNote.valuationCap &&
                  renderTermsItem(
                    'Valuation Cap:',
                    <Badge type={BadgeType.SUCCESS}>
                      ${toCurrencyFormat(safeNote.valuationCap)}
                    </Badge>
                  )}
              </>
            )}

            <div className="tw-flex tw-items-center tw-gap-20 tw-mt-10">
              <Button
                uppercase
                width="full"
                appearance="secondary"
                onClick={handleBackClick}
              >
                Back To Dashboard
              </Button>
              <Button uppercase width="full" onClick={handleViewClick}>
                View Safe
              </Button>
            </div>
          </Col>
        </div>
      </div>

      <div className="tw-hidden">
        <SafeNoteTemplate
          id={SAFE_NOTE_TEMPLATE_ID}
          mfn={safeNote.mfn}
          discountRate={safeNote.discountRate}
          valuationCap={safeNote.valuationCap}
          safeAmount={safeNote.safeAmount}
          companyName={safeNote.senderCompany.name}
          recipientName={safeNote.recipient.fullName}
          highlights={{
            discountRate: false,
            helpSetTerms: false,
            mfn: false,
            valuationCap: false,
          }}
          stateOfIncorporation={safeNote.recipientCompany?.stateOfIncorporation as string}
          anchors={{
            company: SAFE_NOTE_TEMPLATE_ANCHORS.COMPANY,
            recipient: SAFE_NOTE_TEMPLATE_ANCHORS.INVESTOR,
            amount: SAFE_NOTE_TEMPLATE_ANCHORS.AMOUNT,
            discountRate: SAFE_NOTE_TEMPLATE_ANCHORS.DISCOUNT_RATE,
            valuationCap: SAFE_NOTE_TEMPLATE_ANCHORS.VALUATION_CAP
          }}
        />
      </div>
    </div>
  )
}

export default SafePaymentSummary
