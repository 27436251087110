import clsx from 'clsx'
import { noop } from 'lodash'
import { FC, useMemo } from 'react'

import { Avatar, ConditionalRender } from '@/common/components'
import { ToastService } from '@/common/services'
import { StoreType, withStore } from '@/common/store'
import { SubscriptionsModal } from '@/features/auth/modals'
import { CompanyOptions } from '@/features/dashboard'
import { DownloadSafesModal } from '@/features/dashboard/modals'
import { Notifications } from '@/features/notifications'
import { ISummaryItem } from '@/features/profile/api/types'
import { defaultSummary } from '@/features/profile/constants'
import { ICompany } from '@/features/profile/types'
import { getPermission } from '@/features/profile/utils'
import { TeamMemberPermission } from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Button,
  FontWeight,
  Row,
  Text,
  TextTypes,
  Tooltip,
  TooltipKind
} from '@/packages/ui'
import { useNavigator } from '@/router'

import { DashboardTeamMembers } from '../DashboardTeamMembers'
import SafesInfoTooltip from '../SafesInfoTooltip/SafesInfoTooltip'
import styles from './CompanyCard.module.scss'

interface CompanyCardProps {
  company: ICompany
  summary?: ISummaryItem
  myId?: string
  deleted?: boolean
}

const mapStateToProps = ({ user }: StoreType) => ({
  user: user.me,
  restoreCompany: user.restoreCompany
})

const CompanyCard: FC<CompanyCardProps & ReturnType<typeof mapStateToProps>> = (
  props
) => {
  const {
    user,
    deleted,
    restoreCompany,
    summary = defaultSummary,
    company,
    myId
  } = props

  const { activeSubscription } = user || {}
  const navigate = useNavigator()

  const { visible, openModal, closeModal } = useModal<'upgrade' | 'download'>()

  const permission = useMemo(
    () => getPermission(myId, company),
    [myId, company]
  )
  const isCreator = permission === TeamMemberPermission.CREATE

  const entrepreneurCompany = company.type === ProfileTypes.ENTREPRENEUR
  const paidSafes = summary.totalSafeCount - summary.unpaidCount

  const isNewCompany = useMemo(() => {
    const fiveSeconds = 5000
    return (
      new Date().getTime() - new Date(company.createdAt).getTime() <=
      fiveSeconds
    )
  }, [company.createdAt])

  const onRestoreCompany = async () => {
    if (!!activeSubscription) {
      await restoreCompany({
        data: {
          id: company.id
        },
        options: {
          onSuccess: () => {
            ToastService.showSuccess('Your company was restored successfully')
          }
        }
      })
    } else {
      openModal('upgrade')
    }
  }

  const onCreateSafe = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (isCreator) {
      navigate.toDashboardCompany(company.id, {
        'create-safe-note': true
      })
    } else {
      ToastService.showError(
        'You do not have permission to create a SAFE note.'
      )
    }
  }

  const onDownloadSafes = () => {
    openModal('download')
  }

  return (
    <div
      key={company.id}
      className={clsx(
        styles.companyCard,
        deleted && styles.deleted,
        isNewCompany && !deleted && styles.isNewCompany
      )}
      onClick={() =>
        deleted ? noop() : navigate.toDashboardCompany(company.id)
      }
    >
      {visible === 'upgrade' && <SubscriptionsModal closeModal={closeModal} />}
      {visible === 'download' && (
        <DownloadSafesModal company={company} closeModal={closeModal} />
      )}

      <ConditionalRender condition={isNewCompany && !deleted}>
        <span className={styles.newLabel}>NEW</span>
      </ConditionalRender>

      <ConditionalRender condition={deleted}>
        <div className={styles.tooltip}>
          <Tooltip
            kind={TooltipKind.INFO}
            content="We securely archive deleted companies for 30 days, allowing you the opportunity to restore your company during this time. Once 30 days has passed, the company is permanently removed from our records and cannot be restored."
            title="This company is deleted"
          />
        </div>
      </ConditionalRender>

      <Row justify="between" gap={20} className="tw-mb-[10px]">
        <Avatar
          size={56}
          name={company.name}
          image={company.image}
          className={styles.companyLogo}
        />

        <Row items="center" gap={8}>
          <Notifications
            key={company.id}
            color={Color.neutral400}
            minimalistic
            companyId={company.id}
          />
          <CompanyOptions company={company} />
        </Row>
      </Row>

      <Row items="center" gap={6}>
        <Text
          color={Color.neutral500}
          type={TextTypes.BODY_MAIN}
          weight={FontWeight.BOLD}
        >
          {company.name}
        </Text>

        {/* <VerificationStatusBadge company={company} isCreator={isCreator} /> */}
      </Row>

      <Row items="center" gap={4} className="tw-mb-[4px] tw-ml-[-5px]">
        <SafesInfoTooltip
          type="complete"
          role={company.type}
          count={paidSafes}
          amount={summary.paidAmount}
          companyId={company.id}
        />
        <span className={styles.circle} />
        <SafesInfoTooltip
          type="incomplete"
          role={company.type}
          count={summary.unpaidCount}
          amount={summary.unpaidAmount}
          companyId={company.id}
        />
      </Row>

      <Text
        color={Color.neutral400}
        type={TextTypes.BODY_SMALL}
        weight={FontWeight.MEDIUM}
        className={clsx(!deleted && 'tw-mb-[20px]')}
      >
        {entrepreneurCompany ? 'Owner' : 'Invested companies'}:{' '}
        <span className={styles.owner}>
          {entrepreneurCompany
            ? company.owner.fullName
            : summary.numberOfInvestedCompanies}
        </span>
      </Text>

      <ConditionalRender condition={!deleted}>
        <Row items="center" justify="between">
          <DashboardTeamMembers
            minimizedInviteButton
            companyId={company.id}
            companyName={company.name}
            teamMembers={company.teamMembers}
            owner={company.owner}
          />
          {isCreator && entrepreneurCompany && (
            <Button
              uppercase
              appearance="ordinary"
              width="fit"
              onClick={onCreateSafe}
              addonAfter={
                <SharedIcons.Arrow color={Color.primary400} size={24} />
              }
            >
              Create SAFE
            </Button>
          )}
        </Row>
      </ConditionalRender>

      <ConditionalRender condition={deleted}>
        <Row
          items="center"
          justify="between"
          gap={16}
          className={styles.deletedActions}
        >
          <ConditionalRender
            condition={!!activeSubscription}
            fallbackElement={
              <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
                To restore company or download SAFEs, upgrade your plan
              </Text>
            }
          >
            <Button
              appearance="secondary"
              uppercase
              width="full"
              className={styles.downloadBtn}
              onClick={onDownloadSafes}
              textClassName="!tw-text-[14px]"
            >
              Download SAFEs
            </Button>
          </ConditionalRender>

          <Button
            uppercase
            width={!!activeSubscription ? 'full' : 'fit'}
            className="!tw-p-8"
            onClick={onRestoreCompany}
            textClassName="!tw-text-[14px]"
          >
            {!!activeSubscription ? 'Restore company' : 'Upgrade'}
          </Button>
        </Row>
      </ConditionalRender>
    </div>
  )
}

export default withStore(mapStateToProps)(CompanyCard)
